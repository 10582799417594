/*文章接口*/

import {req} from '../utils/request';

export class Article {

  /**
   * 获取用户信息
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getUserinfo = async(params) => {
    return req.post('user.article/getUserinfo', params);
  }

  /**
   * 获取 用户 作品，关注，收藏 统计
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getAuthorStatistics = async(params) => {
    return req.post('user.article/getAuthorStatistics', params);
  }

  /**
   * 获取分类
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getCategoryList = async(params) => {
    return req.post('user.article/getCategoryList', params);
  }

  /**
   * 获取分类名称
   * @param params
   * @returns {Promise<*>}
   */
  getCategoryNameList = async(params) => {
    return req.post('user.article/getCategoryNameList', params);
  }

  /**
   * 获取数据列表
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getListRows = async(params) => {
    return req.post('user.article/getListRows', params);
  }

  /**
   * 获取数据列表
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getList = async(params) => {
    return req.post('user.article/getList', params);
  }

  /**
   * 获取 用户作品列表
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getUserList = async(params) => {
    return req.post('user.article/getUserList', params);
  }

  /**
   * 获取数据列表
   * @param params
   * @returns {Promise<any|undefined>}
   */
  getDetail = async(params) => {
    return req.post('user.article/getDetail', params);
  }

  /**
   * 作品 点赞
   * @param params
   * @returns {Promise<any|undefined>}
   */
  veryGood = async(params) => {
    return req.post('user.user/veryGoodArticle', params);
  }

  /**
   * 列表 编辑
   * @param params
   * @returns {Promise<*|void>}
   */
  edit = async (params) => {
    return req.post('user.user/editArticle', params);
  }

  /**
   * 列表 复制
   * @param params
   * @returns {Promise<*|void>}
   */
  copy = async (params) => {
    return req.post('user.article/copy', params);
  }

  /**
   * 删除
   * @param params
   * @returns {Promise<*|void>}
   */
  del = async (params) => {
    return req.post('user.article/del', params);
  }

  /**
   * 文章状态 显示/隐藏
   * @param params
   * @returns {Promise<*|void>}
   */
  changeStatus = async (params) => {
    return req.post('user.article/changeStatus', params);
  }

  /**
   * 添加访客记录
   * @param params
   * @returns {Promise<*|void>}
   */
  visitorAdd = async (params) => {
    return req.post('user.article/visitorAdd', params);
  }

  /**
   * 获取访客记录
   * @param params
   * @returns {Promise<*|void>}
   */
  getUserVisitor = async (params) => {
    return req.post('user.article/getUserVisitor', params);
  }

}

export default new Article();
